import React from "react";
import defaultThumbnail from "../images/default_thumbnail.png";
import ButtonAction from "./ButtonAction";
import { navigate } from "gatsby";


class RecentBlogPosts extends React.Component {
    constructor(props) {
        super(props);
    }
    
    goTo(e) {
        e.preventDefault();
        navigate('/blog/');
    }

    renderThumbnail(data) {
        
        if(data){
            return (<img alt="" className="w-100 mb-2" src={data.url}></img>)
        } else{
            return (<img alt="" className="w-100 mb-2" src={defaultThumbnail}></img>)
        }
    }

    renderButton(flag) {
        if(flag) {
            return(
                <>
                <div className="d-flex justify-content-center mt-4 mb-8">
                <ButtonAction label="Zobacz blog >" onClick={(e) => this.goTo(e)}></ButtonAction>
            </div>
            
            </>
            );
        }
        return "";
    }

    render() {
        return (
            <div>
                <h1> {this.props.title}</h1>
                <div className="w-50 mb-4">
                    <div className="line__box d-flex">
                        <div className=" line__dashed line__dashed--black"></div>
                    </div>
                </div>  
              
                <div className="row d-flex w-100 xs-flex-column">
                    {this.props.posts.map(({node}) => 
                        <div key={node.slug} className="recent-box p-2 w-25 mb-xs-4">
                            {this.renderThumbnail(node.thumbnail)}
                            <a className="text-uppercase green-color" href={'/blog/'+node.slug}>{node.title}</a>
                            <div dangerouslySetInnerHTML={{__html: node.description}}></div>
                        </div>

                        )}
                </div>
                {this.renderButton(this.props.showButton)}
            </div>
            
        
        );
    }

}

export default RecentBlogPosts;