import React from "react";




class ButtonAction extends React.Component {
    render() {
        return (
            <button className={`btn btn-rounded green-bg main-color fixed-size ${this.props.styleClass}`} onClick={this.props.onClick}>
                {this.props.label}
            </button>
        );
    }
}


export default ButtonAction;