import React from 'react';
import { graphql } from 'gatsby';
import Layout from './../components/Layout';
import RecentBlogPosts from '../components/RecentBlogPosts';


const BlogPage = ({data}) => {
  const posts = data.allDatoCmsPost.edges;
  if(posts.length){
    return (
      <Layout>
        <div className="main-wrapper">
          <RecentBlogPosts title="Wszystkie wpisy" posts={posts}></RecentBlogPosts>
        </div>

      </Layout>
    );
  }else{
    return (
      <Layout>
        <div className="main-wrapper">
          <h2 className="font-bold text-align-center"> Nie ma jeszcze żadnych wpisów zapraszamy ponownie wkrótce. </h2>
        </div>
      </Layout>
    );
  }
  
};

export default BlogPage;

export const query = graphql`
query {
  allDatoCmsPost {
    edges {
      node {
        title,
        description,
        slug,
        thumbnail {
          url
        }
      }
    }
  }
}
`;
